<template>
    <button v-if="showNewRecordButton" class="btn btn-link btn-sm ps-1 pe-2 border-end" @click="() => addNewRecordsToTheBottom ? createNewRecord() : closeNewRecords()"
        :title="$t('Toggle new records panel')">
        <i v-if="addNewRecordsToTheBottom" class="bi bi-plus-lg"></i>
        <i v-else class="bi bi-x-lg"></i>
    </button>
   <slot name="recordcount" :dataObject="dataObject" :refreshRowCount="refreshRowCount">
        <div v-if="dataObject?.hasPagedData && dataObject.pagedData.enabled" class="px-2 d-flex" style="white-space: nowrap;">
            <Paginator :dataObject="dataObject" disablePageSizeDropdown/>
        </div>
        <div v-else class="px-2 d-flex" style="white-space: nowrap; min-width: 80px;">
                <b v-if="isRowCountLoading" class="align-self-center">
                    <span v-if="dataObject?.current?.index !== undefined" :title="$t('Current Row')">{{number.format(dataObject.current?.index+1, '1 234')}} / </span>
                    <!-- <div class="spinner-border spinner-border-sm ms-1 align-self-center" role="status" -->
                        <!-- style="--bs-spinner-width: 0.75rem; --bs-spinner-height: 0.75rem;"> -->
                        <!-- <span class="sr-only"></span> -->
                    <!-- </div> -->
                    <!-- <button v-if="rowCount == -1" class="btn btn-sm btn-link text-danger" :title="$t('Failed to load the row count, click to try again')" @click="refreshRowCount" style="font-size: 0.75rem; padding-left: 0px;"> -->
                        <!-- <i class="bi bi-exclamation-circle"></i> -->
                    <!-- </button> -->
                
                </b>

                <template v-else-if="infoItemsType === InfoItemsType.ArrayGrid">
                    <b v-if="filterObject.appliedFilterString" class="align-self-center">
                        <span :title="$t('Filtered Rows')">{{number.format(filteredRowCount, '1 234')}}</span> <span :title="$t('Total Rows')">({{number.format(rowCount, '1 234')}})</span>
                    </b>
                    <b v-else class="align-self-center">
                        <span :title="$t('Total Rows')">{{number.format(rowCount, '1 234')}}</span>
                    </b>
                </template>

                <b v-else-if="dataObject?.treeify?.enabled" class="align-self-center">
                    <span :title="$t('Loaded Rows')">{{number.format(dataObject.data.length, '1 234')}}</span> <span :title="$t('Total Rows')">({{number.format(dataObject.dataHandler.clientSideData.length, '1 234')}})</span>
                </b>

                <b v-else-if="rowCount == null || rowCount == -1"  class="align-self-center">
                    <span v-if="dataObject.current?.index !== undefined" :title="$t('Current Row')">{{number.format(dataObject.current?.index+1, '1 234')}} / </span>
                    <!-- <button v-if="rowCount == -1" class="btn btn-sm btn-link text-danger" :title="$t('Failed to load the row count, click to try again')" @click="refreshRowCount" style="font-size: 0.75rem; padding-left: 0px;"> -->
                        <!-- <i class="bi bi-exclamation-circle"></i> -->
                    <!-- </button> -->
                    <button v-if="rowCount == -1 || rowCount == null" class="btn btn-sm btn-link" :title="$t('Load row count')" @click="refreshRowCount" style="font-size: 0.75rem; padding-left: 0px;">
                        <i class="bi bi-question-circle"></i>
                    </button>
                </b>
                <b v-else  class="align-self-center" style="min-width: 50px;">
                    <span v-if="dataObject.current?.index !== undefined" :title="$t('Current Row')">{{number.format(dataObject.current?.index+1, '1 234')}} / </span>
                    <span :title="$t('Row Count')">{{number.format(rowCount, '1 234')}}</span>
                </b>
        </div>
    </slot>

    <button class="btn btn-link btn-sm" @click="refreshData"><i class="bi bi-arrow-clockwise"></i></button>
    <DataGridMenuDropdown v-if="_dataGridControl"/>
    <o-filter-list-dropdown v-if="_dataGridControl && dataObject" :data-object="dataObject"></o-filter-list-dropdown>

    <OFilterString v-if="!noFilterString" :filterObject="filterObject" class="text-truncate mt-1" style="display: flex; align-items: baseline;">
        <template #clear="{clear}">
            <button class="btn btn-sm btn-link px-1 pt-0 ms-2 text-danger" @click="clear()" :title="$t('Clear current filter')">
                <i class="bi bi-x-circle-fill"></i>
            </button>
        </template>
    </OFilterString>
    <button v-else-if="noFilterString && filterObject.filterString" class="btn btn-sm btn-link" @click="filterObject.clear()" :title="$t('Clear current filter')">
        <i class="bi bi-x-circle-fill"></i>
    </button>

    <OSaveFilter :data-object="dataObject" class-name="d-flex" 
        v-if="dataObject && dataObject.filterObject.filterString"
        save-class="btn btn-link btn-sm ms-auto"
        save-as-class="btn btn-link btn-sm ms-1 text-nowrap"/>
    <ODropdown v-if="dataObject?.filterObject?.persistentFilterId">
        <template #default="{target, open}">
            <button :ref=target class="btn btn-sm btn-link py-0" @click="dataObject?.filterObject.persistentFilterEnabled && dataObject?.filterObject.appliedFilterString? open() : togglePersistentFilter()" 
                :title="$t('Set filter as default')"
                :style="dataObject.filterObject.persistentFilterEnabled ? 'color: var(--o365-active-filter)!important;' : ''">
                <i v-if="dataObject.filterObject.persistentFilterEnabled" class="bi bi-person-check-fill"></i>
                <i v-else class="bi bi-person-check-fill"></i>    
            </button>
        </template>
        <template #dropdown="{container, close}">
            <div class="dropdown-menu show" :ref="container">
                <button class="dropdown-item" :title="$t('Set current filter as default')" @click="() => {togglePersistentFilter(); close()}">
                    {{$t('Set Applied Filter As Default')}}
                </button>
                <button class="dropdown-item" :title="$t('Clear default filter')" @click="() => {togglePersistentFilter(true); close()}">
                    {{$t('Clear Default Filter')}}
                </button>
            </div>
        </template>
    </ODropdown>
    <div v-if="selectionControl?.selectedRows?.length && !selectionControl?.selectAllLoading" class="d-flex align-items-baseline text-truncate mt-1 text-truncate mt-1">
        <label class="text-truncate">
            {{selectionControl.selectedUniqueKeys?.size ?? selectionControl.selectedDataItems?.size}} {{$t('row(s) selected')}}
        </label>
        <button v-if="!noSelectClear" class="btn btn-sm btn-link px-1 pt-0" @click="() => { dataObject ? dataObject.selectionControl.selectAll(false) : selectionControl.selectAll(false) }">{{$t('Clear')}}</button>
    </div>
    <template v-if="_dataGridControl.dataObject?.layoutManager && _dataGridControl?.props.showLayoutsAtBottom">
        <span class="align-self-center ms-2">{{ $t('Layout') }}:</span>
        <LayoutActions />
    </template>
    <slot name="actionsEnd"></slot>
    <OLayoutStatus v-if="showLayoutStatus" />

</template>

<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
import type DataGridContorl from './DataGridControl.ts';

import { OFilterListDropdown, OSaveFilter, OFilterString } from 'o365-filter-components';
import OLayoutStatus from './components.LayoutStatus.vue';
import { $t, InjectionKeys, numberUtils as number } from 'o365-utils';
import DataGridMenuDropdown from './components.GridMenuDropdown.vue';
import { ODropdown } from 'o365-ui-components';
import { useAsyncComponent } from 'o365-vue-utils';
import { inject, computed } from 'vue';

const Paginator = useAsyncComponent('o365-data-components/DataObject.Paginator.vue', { importFn: () => import('o365-data-pagination').then(x => x.OPaginator)});
const LayoutActions = useAsyncComponent('./components.Layouts.Actions.vue', { importFn: () => import('./components.Layouts.Actions.vue') });

const props = defineProps<{
    dataObject?: DataObject,
    layoutStatus?: boolean,
    noSelectClear?: boolean,
    noFilterString?: boolean,
    dataGridControl?: DataGridContorl,
}>();

enum InfoItemsType {
    DataObject,
    DataObjectGrid,
    ArrayGrid,
    Invalid
};

const injectedDataGridControl = inject(InjectionKeys.dataGridControlKey, null);

const _dataGridControl = computed(() => {
    return props.dataGridControl ?? injectedDataGridControl?.value
});

/** Current info items type */
const infoItemsType = computed(() => {
    if (_dataGridControl.value) {
        if (_dataGridControl.value.dataObject) {
            return InfoItemsType.DataObjectGrid;
        } else {
            return InfoItemsType.ArrayGrid;
        }
    } else if (props.dataObject) {
        return InfoItemsType.DataObject
    } else {
        return InfoItemsType.Invalid;
    }
});

const isRowCountLoading = computed(() => {
    switch(infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.state.isRowCountLoading
        default:
            return props.dataObject.isRowCountLoading
    }
});

const rowCount = computed(() => {
    switch(infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
            return _dataGridControl.value!.dataObject!.rowCount;
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.utils.rowCount;
        default:
            return props.dataObject.rowCount
    }
});

const filteredRowCount = computed(() => {
    switch(infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
            return _dataGridControl.value!.dataObject!.filteredRowCount;
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.utils.filteredRowCount;
        default:
            return props.dataObject.filteredRowCount
    }
});

const filterObject = computed(() => {
    switch (infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.filterObject;
        default:
            return props.dataObject.filterObject
    }
});

const selectionControl = computed(() => {
    switch (infoItemsType.value) {
        case InfoItemsType.DataObjectGrid:
        case InfoItemsType.ArrayGrid:
            return _dataGridControl.value!.selectionControl;
        default:
            return props.dataObject.selectionControl;
    }
});

const showLayoutStatus = computed(() => {
    return (props.dataObject?.layoutManager && (props.layoutStatus ?? _dataGridControl.value));
});


async function refreshData() {
    if (_dataGridControl.value?.load && props.dataObject?.treeify == null) {
        _dataGridControl.value.load();
    } else if (props.dataObject.treeify && props.dataObject.treeify.enabled) {
        // Load new data into treeify
        props.dataObject.treeify.disable();
        if (props.dataObject.filterObject.appliedFilterString != null) {
            // Need to apply filter after getting new data only
            const prevFilterString = props.dataObject.recordSource.filterString;
            props.dataObject.recordSource.filterString = null;
            await props.dataObject.load()
            props.dataObject.recordSource.filterString = prevFilterString;
        } else {
            await props.dataObject.load();
        }
        props.dataObject.treeify.enable()
        await props.dataObject.load();
    } else {
        props.dataObject.load();
    }
}

const showNewRecordButton = computed(() => {
    return props.dataObject?.allowInsert && !_dataGridControl.value?.disableBatchRecords && !_dataGridControl.value?.hasNodeData && !_dataGridControl.value?.props.hideNewRecords ;
});

const addNewRecordsToTheBottom = computed(() => {
    return !_dataGridControl.value.dataObject?.batchDataEnabled || _dataGridControl.value.newRecordsPosition != 'bottom';
});

async function closeNewRecords() {
    const hasChanges = _dataGridControl.value.dataObject.batchDataEnabled && _dataGridControl.value.dataObject.batchData.data.some(x => x.hasChanges);
    if (hasChanges) {
        try {
            const { confirm: o365_confirm} = await import('o365-vue-services');
            await o365_confirm({
                message: $t('Are you sure you want to close the new records panel?'),
                title: $t('Unsaved changes')
            });
        } catch (_) {
            return;
        }
    }
    _dataGridControl.value.closeBatchRecords();
}

function togglePersistentFilter(pClear = false) {
    if (props.dataObject?.filterObject) {
        if (props.dataObject.filterObject.appliedFilterString && !pClear) {
            props.dataObject.filterObject.setPersistentFilter();
            props.dataObject.filterObject.persistentFilterEnabled = true;
        } else {
            props.dataObject.filterObject.removePersistentFilter();
            props.dataObject.filterObject.persistentFilterEnabled = false;
        }
        // if (props.dataObject.filterObject.persistentFilterEnabled) {
            // props.dataObject.filterObject.persistentFilterEnabled = false;
            // props.dataObject.filterObject.removePersistentFilter();
        // } else {
            // props.dataObject.filterObject.persistentFilterEnabled = true;
            // props.dataObject.filterObject.setPersistentFilter();
        // }
    }
}

async function createNewRecord() {
    _dataGridControl.value.enableBatchRecords('bottom');
}

async function refreshRowCount() {
    props.dataObject?.recordSource.loadRowCounts({
        timeout: 30
    });
}
</script>